<template>
  <div id="name">
    <span class="finish" @click="updatedUser">完成</span>
    <van-field v-model="value" clearable placeholder="请输入真实姓名" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { removeToken } from "@/utils/auth";
export default {
  name: "name",
  data() {
    return {
      value: "",
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
  },
  created() {
    this.userInfo.userEntity.actualName &&
      (this.value = this.userInfo.userEntity.actualName);
  },
  methods: {
    async updatedUser() {
      if (!/^(?:[\u4e00-\u9fa5·]{2,16})$/.test(this.value)) {
        this.Toast.fail("请输入真实姓名");
        return;
      }
      let userInfo = JSON.parse(JSON.stringify(this.userInfo));

      delete userInfo.userDetailEntity.updateTime;
      delete userInfo.userDetailEntity.createTime;
      delete userInfo.userDetailEntity.delete;
      delete userInfo.userEntity.updateTime;
      delete userInfo.userEntity.createTime;
      delete userInfo.userEntity.delete;
      userInfo.userEntity.password = "";
      userInfo.userEntity.actualName = this.value;
      await this.$API.user
        .updateUser({
          object: {
            id: userInfo.id,
            userDetailEntity: userInfo.userDetailEntity,
            userEntity: userInfo.userEntity,
          },
        })
        .then(async (res) => {
          // removeToken();
          this.Toast.success("更改真实姓名成功");
          await this.$store.dispatch("user/updatedUserInfo");

          if (this.$route.query.taskId) {
            await this.$store.dispatch(
              "user/taskFinished",
              this.$route.query.taskId
            );
            this.$router.go(-1);
          } else {
            await this.$store.dispatch("user/taskFinished");
            this.$router.replace("/userData");
          }
        })
        .catch((err) => {
          console.log(err);
          this.Toast.fail(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#name {
  height: 100%;
  padding-top: 46px;
  background: #f5f5f5;
  .finish {
    position: absolute;
    top: 12px;
    right: 15px;
    z-index: 1000;
    color: #666666;
  }
  .van-cell {
    margin-top: 10px;
  }
}
</style>
